import React from "react"
import Layout from "../../components/layout"
import {graphql} from "gatsby"
import SEO from "../../components/seo"
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import toaster from "toasted-notes";
import 'toasted-notes/src/styles.css';

import "../../styles/internship.scss"
import InternBg from "../../images/internship_bg.png";

const allowedTypes = /\bdoc\b|\bdocx\b|\bpdf\b|\bzip\b|\brar\b/;

export default class Internship extends React.Component {
    state = {
        interests: {
            HTML: false,
            CSS: false,
            jQuery: false,
            Laravel: false,
            React: false
        },
        contactType: '',
        name: '',
        phone: '',
        email: '',
        message: '',
        selectedFile: '',
        outPutText: ''


    }
    handleChange = (e) => {
        switch (e.target.name) {
            case 'selectedFile':
                this.setState({ selectedFile: e.target.files[0] });
                this.setState({ outPutText: e.target.files[0].name});
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }
    toggleChange = (e) => {
        let section = {...this.state.interests};

        section[e.target.name] = e.target.checked;
        this.setState({ interests: section });

    }
    resetInput = () => {
        const { interests } = this.state;

        Object.keys(interests).forEach(function (key) {
            interests[key] = false;
        });
        this.setState({interests});

    }
    handleSubmit = (formValues) => {

        const { contactType, name, phone, email, message } = formValues;
        const { selectedFile, interests } = this.state;

        let formData = new FormData();

        let interestsList = [];

        Object.keys(interests).forEach(function (key) {
            if (interests[key] === true) {
                interestsList.push(key);
            }
        })
        this.setState({interests});

        formData.append('interests', interestsList);
        formData.append('contactType', contactType);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('selectedFile', selectedFile);
        axios.post("https://backend.s7codedesign.com", formData)

        .then(function (response) {
            console.log("response", response);
            if (response.status == 200) {
                toaster.notify("You application has been successfully sent. We will answer you as soon as possible.", {
                    duration: 3000,
                    position: "top-right"
                  });
            }

        })
        .catch(function (error) {
            toaster.notify("Application sending fail. Our develop team is working to fix a problem.", {
                duration: 3000,
                position: "top-right"
              });
        })

    }
    render() {
        return (
            <Layout>
                <SEO title="Internship" keywords={[`S7 Design`, `contact`]} />
                <div>
                    <div className="internship-banner" style={{ backgroundImage: `url(${InternBg})` }}>
                        {/*<div className="container h-100">*/}
                            {/*<div className="row align-content-end h-100">*/}
                                {/*<div className="offset-lg-4 offset-md-3 offset-1 col-md-6 col-11 internship-banner--front" id="internship-banner--front">*/}
                                    {/*/!*<div><Img fluid={props.data.internship_banner.childImageSharp.fluid} /></div>*!/*/}
                                    {/*/!*<div className="rocket"><Img id="rocket" fluid={props.data.rocket.childImageSharp.fluid} /></div>*!/*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="internship-main">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <h1 className="text-center">Internship</h1>
                                    <p>Apply knowledge in practice, gain practical and business skills, notice the difference
                                        between academic and professional work.
                                        Our interns have opportunity to get to work on important projects that have an
                                        immediate business impact. The mentorship of people who are professional in their work is a great thing and opportunity to actually learn the job from the best.
                                        Don`t let your effort to gain real world experience become a waste of time, so join us.
                                    </p>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-5">
                                <div className="col-10">
                                    <h2 className="text-center pb-5">Rise your potential</h2>
                                    <p>Mark the boxes with the knowledge and skills you are interested to improve and work within our company.</p>
                                    <Formik
                                        initialValues={{contactType: 'Official website: internship contact form', name: '', email: '', phone: '', message:'', selectedFile: '' }}
                                        validate={values => {
                                            let errors = {};


                                            // email
                                            if (!values.email) {
                                                errors.email = 'Email field is required';
                                            } else if (
                                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                            ) {
                                                errors.email = 'Invalid email address';
                                            }
                                            // name
                                            if (!values.name) {
                                                errors.name = 'Name field is required';
                                            } else if (
                                                !/^[A-ZšđčćžŠĐČŽĆ ]{2,}$/i.test(values.name)
                                            ) {
                                                errors.name = 'Name must contain at least 2 letters.';
                                            }
                                            // phone
                                            if (values.phone && !/^[0-9._%+-]{6,}$/i.test(values.phone)) {
                                                errors.phone = 'Invalid phone number format.';
                                            }
                                            // message
                                            if (!values.message) {
                                                errors.message = 'Message field is required';
                                            } else if (
                                                !/^[A-ZšđčćžŠĐČŽĆ 0-9.,;:" '_%+-]{10,}$/i.test(values.message)
                                            ) {
                                                errors.message = 'Please fill up message field';
                                            }
                                            // files
                                            const {selectedFile} = this.state;

                                            if (!selectedFile) {
                                                errors.selectedFile = 'File attachment is mandatory';
                                            } else if (
                                                !selectedFile.name.split('.')[1].match(allowedTypes)
                                            ) {
                                                errors.selectedFile = 'Wrong file format.';
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            setTimeout(() => {
                                                setSubmitting(false);
                                                this.handleSubmit(values);
                                                resetForm();
                                                this.setState({outPutText: ''});
                                                this.setState({interests: {
                                                    HTML: false,
                                                    CSS: false,
                                                    jQuery: false,
                                                    Laravel: false,
                                                    React: false
                                                }});
                                            }, 400);
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <Field type="hidden" name="contactType" className="invisible-field"/>
                                                <div className="form-row">
                                                    <div className="form">
                                                        <div className="form-check">
                                                            <div className="row form-check-wrap">
                                                                <div className="form-check-wrap--input">
                                                                    <input type="checkbox" name="HTML" checked={this.state.interests.HTML} onChange={(e) =>{this.toggleChange(e)}} id="check-1"/>
                                                                    <label htmlFor="check-1"></label>
                                                                </div>
                                                                <div className="form-check-wrap--label">
                                                                    <p>HTML</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="form-check">
                                                            <div className="row form-check-wrap">
                                                                <div className="form-check-wrap--input">
                                                                    <input type="checkbox" name="CSS" checked={this.state.interests.CSS} onChange={(e) =>{this.toggleChange(e)}} id="check-2"/>
                                                                    <label htmlFor="check-2"></label>
                                                                </div>
                                                                <div className="form-check-wrap--label">
                                                                    <p>CSS</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="form-check">
                                                            <div className="row form-check-wrap">
                                                                <div className="form-check-wrap--input">
                                                                    <input type="checkbox" name="jQuery" checked={this.state.interests.jQuery} onChange={(event) =>{this.toggleChange(event)}} id="check-3"/>
                                                                    <label htmlFor="check-3"></label>
                                                                </div>
                                                                <div className="form-check-wrap--label">
                                                                    <p>jQuery</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="form-check">
                                                            <div className="row form-check-wrap">
                                                                <div className="form-check-wrap--input">
                                                                    <input type="checkbox" name="Laravel" checked={this.state.interests.Laravel} onChange={(event) =>{this.toggleChange(event)}} id="check-4"/>
                                                                    <label  htmlFor="check-4"></label>
                                                                </div>
                                                                <div className="form-check-wrap--label">
                                                                    <p>Laravel</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="form-check">
                                                            <div className="row form-check-wrap">
                                                                <div className="form-check-wrap--input">
                                                                    <input type="checkbox" name="React" checked={this.state.interests.React} onChange={(event) =>{this.toggleChange(event)}} id="check-5"/>
                                                                    <label  htmlFor="check-5"></label>
                                                                </div>
                                                                <div className="form-check-wrap--label">
                                                                    <p>React</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group w-100 position-relative">
                                                        <label>NAME</label>
                                                        <Field type="text" name="name"  placeholder="" autoFocus/>
                                                        <ErrorMessage name="name" component="span" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6 col-12 position-relative">
                                                        <label>PHONE</label>
                                                        <Field type="text" name="phone"  placeholder=""/>
                                                        <ErrorMessage name="phone" component="span" />
                                                    </div>
                                                    <div className="form-group col-md-6 col-12 position-relative">
                                                        <label>EMAIL</label>
                                                        <Field type="email" name="email" placeholder=""/>
                                                        <ErrorMessage name="email" component="span" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group w-100 position-relative">
                                                        <label>WE'RE CURIOS TO FIND OUT MORE ABOUT YOU!</label>
                                                        <Field name="message" component="textarea" rows="1" placeholder="" />
                                                        <ErrorMessage name="message" component="span" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="attach-file position-relative">
                                                        <input id="file" name="selectedFile" type="file" onChange={(event) =>{this.handleChange(event)}} />
                                                        <label for="file" className="icon-attach custom-file-label text-uppercase"> attach your CV (doc, docx, pdf, zip, rar)</label>
                                                        {/* <ErrorMessage name="selectedFile" component="span" /> */}
                                                        { this.state.selectedFile && !this.state.selectedFile.name.split('.')[1].match(allowedTypes) && (<div className="customErrorMsg">Wrong file format.</div>)}
                                                        { !this.state.selectedFile ? (<div className="customErrorMsg">File attachment is mandatory</div>) : ''}
                                                        { this.state.outPutText && (<div className="fileNameText">{this.state.outPutText}</div>)}
                                                    </div>
                                                </div>
                                                <div className="form-row justify-content-end">
                                                    <button className="btn" type="submit" disabled={isSubmitting}>APPLY NOW</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )

    }
}

export const pageQuery = graphql`
  query {
    internship_banner: file(relativePath: { eq: "internship_b.png" }) {
      ...fluidImage
    }
    rocket: file(relativePath: { eq: "rocket.png" }) {
      ...fluidImage
    }
  }
`
